<template lang="pug">
div
  van-card(
    v-for='item in list' :key="item.id"
    :thumb="item.fur_image"
    :title="item.fur_name"
    :desc="item.introduce"
    @click="$router.push({name: 'detail', query: {id: item.id}})"
    )
</template>

<script>
export default {
  data () {
    return {
      list: []
    }
  },
  methods: {
    async getInitData() {
      const urlObj = this.$route.query
      try {
        let data = await this.$api.GetCompanyGoods(urlObj.id)
        this.list = data.data.items
        let result = await this.$api.GetCompanyGoodsShow(urlObj.id)
          let dataIds = result.data.ids.split(',')
          this.list.forEach((item, num) => {
            let index = dataIds.indexOf(item.id)
            item.order = index
          })
          this.list = this.sortByKey(this.list, 'order')
      } catch (error) {
        console.log(error)
      }
    },
    // 数组对象顺序方法排序:
    sortByKey: function (array, key) {
      return array.sort((a, b) => {
        var x = a[key]
        var y = b[key]
        return ((x < y) ? -1 : ((x > y) ? 1 : 0))
      })
    }
  },
  mounted () {
    this.$emit('on-nav', {
      rightText: '主页',
      onClickRight: () => {
        this.$router.push({name: 'home'})
      }
    })
    this.getInitData()
  }
}
</script>